import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/view/layout/Layout.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/view/pages/home/Home.vue"),
        meta: { title: "Результаты" },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/view/pages/profile/Profile.vue"),
        meta: { title: "Профиль" },
      },
      {
        path: "/plots",
        name: "plots",
        component: () => import("@/view/pages/plots/Plots.vue"),
        meta: { title: "Динамика" },
      },
    ],
  },
  {
    name: "loginwithtoken",
    path: "/r/:token",
    component: () => import("@/view/pages/auth/Login.vue"),
    props: (route) => ({ token: route.params.token }),
    meta: { title: "Вход" },
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/view/pages/auth/Login.vue"),
    meta: { title: "Вход" },
  },
  {
    name: "enterpassword",
    path: "/enterpassword",
    component: () => import("@/view/pages/auth/EnterPassword.vue"),
    meta: { title: "Вход" },
  },
  {
    name: "confirmemail",
    path: "/confirmemail",
    component: () => import("@/view/pages/ConfirmEmail.vue"),
    props: (route) => ({ token: route.query.token }),
    meta: { title: "Валидация почты" },
  },
  {
    name: "covid",
    path: "/covid/:file",
    component: () => import("@/view/pages/covid/Covid.vue"),
    props: (route) => ({ file: route.params.file }),
    meta: { title: "Результат COVID" },
  },
  {
    name: "terms",
    path: "/terms-of-use",
    component: () => import("@/view/pages/TermsOfUse.vue"),
    meta: { title: "ОФЕРТА НА ЗАКЛЮЧЕНИЕ ДОГОВОРА" },
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/view/pages/error/Error-1.vue"),
    meta: { title: "Ошибка" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
