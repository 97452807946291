import { Module } from "vuex-smart-module";
// import getters from "./getters";
// import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const layout = new Module({
  mutations: mutations,
  //actions: actions,
  state: state,
  //getters: getters
});

export default layout;
