import AuthService from "@/services/auth.service";
import JwtService from "@/services/jwt.service";

import { Actions, Context } from "vuex-smart-module";
import AuthState from "./state";
import AuthMutations from "./mutations";
import AuthGetters from "./getters";
import { TokenClient } from "@/api/api";

import error from "../error";
import { Store } from "vuex";

export default class AuthActions extends Actions<
  AuthState,
  AuthGetters,
  AuthMutations,
  AuthActions
> {
  error!: Context<typeof error>;

  $init(store: Store<any>): void {
    this.error = error.context(store);
  }

  loginbytoken(payload: { token: string }) {
    this.mutations.setLoading();
    return new Promise((resolve, reject) => {
      const client = new TokenClient();
      client
        .token({
          token: payload.token
        })
        .then(({ token, expiredAt }) => {
          this.mutations.setAuth({ token: token!, expiredAt: expiredAt! });
          resolve({ token, expiredAt });
        })
        .catch((exception) => {
          console.warn(exception);
          this.mutations.setLoading(false);
          reject();
        });
    });
  }

  login(payload: { password: string }) {
    this.mutations.setLoading();
    return new Promise((resolve) => {
      const client = new TokenClient();
      client
        .authenticate({
          login: this.state.phone!,
          rememberMe: this.state.rememberMe,
          password: payload.password,
        })
        .then(({ token, expiredAt }) => {
          this.mutations.setAuth({ token: token!, expiredAt: expiredAt! });
          resolve({ token, expiredAt });
        })
        .catch((exception) => {
          const errors = JSON.parse(exception.response);
          if (errors.error != undefined ) {
            const validationErrors = JSON.parse(errors.error);
            if(Array.isArray(validationErrors)){
              this.error.mutations.setError(Object.values(validationErrors));
            } else{
              this.error.mutations.setError([errors.error]);
            }
          } else {
            this.error.mutations.setError(exception.response);
          }
          this.mutations.setLoading(false);
        });
    });
  }

  getTempPassword(payload: {
    phone: string;
    birthDay: string;
    token: string | null;
    rememberMe: boolean;
  }) {
    return new Promise<void>((resolve, reject) => {
      const client = new TokenClient();
      client
        .sendonetimecode({
          phoneNumber: payload.phone,
          birthDay: payload.birthDay,
        })
        .then((response) => {
          this.mutations.setPhone(payload);
          resolve();
        })
        .catch((exception) => {
          const errors = JSON.parse(exception.response);
          if (errors.error != undefined ) {
            const validationErrors = JSON.parse(errors.error);
            if(Array.isArray(validationErrors)){
              this.error.mutations.setError(Object.values(validationErrors));
            } else{
              this.error.mutations.setError([errors.error]);
            }
          } else {
            this.error.mutations.setError(exception.response);
          }
          reject();
        });
    });
  }

  getRepeatTempPassword() {
    return new Promise<void>((resolve, reject) => {
      const client = new TokenClient();
      client
        .sendonetimecode({
          phoneNumber: this.state.phone!,
          birthDay: this.state.birthDay!,
        })
        .then((response) => {
          resolve();
        })
        .catch((exception) => {
          const errors = JSON.parse(exception.response);
          if (errors.error != undefined ) {
            const validationErrors = JSON.parse(errors.error);
            if(Array.isArray(validationErrors)){
              this.error.mutations.setError(Object.values(validationErrors));
            } else{
              this.error.mutations.setError([errors.error]);
            }
          } else {
            this.error.mutations.setError(exception.response);
          }
          reject();
        });
    });
  }

  relogin(payload: string) {
    this.mutations.setLoading();
    return new Promise((resolve) => {
      const client = new TokenClient();
      client.setAuthToken(AuthService.refreshToken).then(() => {
        client
          .relogin({ login: payload })
          .then(({ token, expiredAt }) => {
            this.mutations.setAuth({ token: token!, expiredAt: expiredAt! });
            resolve({ token, expiredAt });
          })
          .catch((exception) => {
            console.debug(exception);
            const errors = JSON.parse(exception.response);
            if (errors.error != undefined ) {
              const validationErrors = JSON.parse(errors.error);
              if(Array.isArray(validationErrors)){
                this.error.mutations.setError(Object.values(validationErrors));
              } else{
                this.error.mutations.setError([errors.error]);
              }
            } else {
              this.error.mutations.setError(exception.response);
            }
          });
      });
    });
  }

  logout() {
    return new Promise<void>((resolve) => {
      const client = new TokenClient();
      client
        .revoketoken()
        .then(() => {
          this.mutations.purgeAuth();
          resolve();
        })
        .catch((exception) => {
          this.mutations.purgeAuth();
          console.log(exception.message);
          resolve();
        });
    });
  }

  async verifyAuth() {
    const client = new TokenClient();
    await client.setAuthToken(AuthService.refreshToken);
    client
      .verify()
      .then((response) => {
        this.mutations.setLogin(response);
        this.mutations.setAuth(JSON.parse(JwtService.getToken()!));
      })
      .catch((exception) => {
        console.debug(exception);
        this.mutations.purgeAuth();
      });
  }
}
