import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store, { authModule } from "./store";

Vue.config.productionTip = false;

import PortalVue from "portal-vue";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import { InlineSvgPlugin } from "vue-inline-svg";
import { BootstrapVue } from "bootstrap-vue";
import Fragment from "vue-fragment";
import VueYandexMetrika from "vue-yandex-metrika";
import VueApexCharts from "vue-apexcharts";

Vue.use(VueYandexMetrika, {
  id: 81025033,
  env: process.env.NODE_ENV,
  debug: true,
});

Vue.use(Fragment.Plugin);
Vue.use(PortalVue);
Vue.use(PerfectScrollbar);
Vue.use(InlineSvgPlugin);
Vue.use(BootstrapVue);
Vue.use(VueApexCharts);

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  if (to.name !== "login" && to.name !== "enterpassword") {
    Promise.all([authModule.actions.verifyAuth()]).then(() => next());
  } else {
    next();
  }

  Vue.nextTick(() => {
    document.title = to.meta.title || "test-book.ru";
  });
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach((to, from) => {
  // Ensure we checked auth before each page load.
  if (to.path == from.path) {
    return;
  }

  // track page visit
  (Vue as any).$metrika.hit(to.path, {
    referer: from.path,
    params: { phone: authModule.state.phone },
  });
});

Vue.component("apexchart", VueApexCharts);

import ConfirmPhone from "@/view/pages/profile/components/ConfirmPhone.vue";
const confirmPhone = Vue.component("confirmPhone", ConfirmPhone);

new Vue({
  components: {
    confirmPhone: confirmPhone,
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
