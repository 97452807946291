import { IndicatorVm } from "@/api/api";
import { Getters } from "vuex-smart-module";
import PlotState from "./state";

export default class PlotGetters extends Getters<PlotState> {
  get series() {
    return this.state.indicatorsSeries.filter((s) =>
      this.state.selectedIndicators?.some((i) => i == s.indicator)
    );
  }

  get groupedIndicators() {
    const results = this.state.indicators;
    if (results == null) return {};
    return results.reduce(
      (groups: { [key: string]: IndicatorVm[] }, indicator: IndicatorVm) => {
        const bm = indicator.BiomaterialName || "";
        if (!groups[bm]) {
          groups[bm] = [];
        }
        groups[bm].push(indicator);
        return groups;
      },
      {}
    );
  }
}
