import { TokenClient, TokenVm } from "@/api/api";
import jwtService from "./jwt.service";

let refreshTokenRequest: Promise<TokenVm> | null = null;

// let refreshTokenRequest: Promise<TokenVm> | null = null;

export const refreshToken = async (): Promise<void> => {
  try {
    if (refreshTokenRequest === null) {
      const client = new TokenClient();
      refreshTokenRequest = client.refreshtoken();
    }
    const token = await refreshTokenRequest;
    refreshTokenRequest = null;
    if (!token) return;
    const rememberMe = JSON.parse(
      localStorage.getItem("rememberMe") || "false"
    ) as boolean;

    jwtService.saveToken(JSON.stringify(token), rememberMe);
  } catch (exception) {
    refreshTokenRequest = null;
    console.log(exception);
  }
};

export default { refreshToken };
