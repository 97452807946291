


































import { Component, Vue, Prop } from "vue-property-decorator";
import OtpInput from "@/view/content/oneTimePassword/OtpInput.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { PatientClient } from "@/api/api";
import AuthService from "@/services/auth.service";

@Component({
  components: {
    OtpInput,
    VueCountdown,
  },
})
export default class Home extends Vue {
  @Prop({ required: true })
  phone!: string;

  @Prop({ required: true })
  patientId!: string;

  code = "";
  counting = true;
  countdown = 60000;

  setPassword(password: string) {
    this.code = password;
  }

  onCountdownEnd() {
    this.counting = false;
    this.countdown = this.countdown * 2;
  }

  canRepeatSendSms() {
    return this.countdown <= 1920000;
  }

  async getSms() {
    if (!this.canRepeatSendSms) {
      return;
    }
    const client = new PatientClient();
    await client.setAuthToken(AuthService.refreshToken);
    client
      .sendphoneconfirmationcode({
        phoneNumber: this.phone,
        patientId: this.patientId,
      })
      .then(() => {
        this.counting = true;
      });
  }

  transformSlotProps(props: { [key: string]: number }) {
    const formattedProps = {} as { [key: string]: string };

    Object.entries(props).forEach(([key, value]) => {
      formattedProps[key] = value < 10 ? `0${value}` : String(value);
    });

    return formattedProps;
  }
}
