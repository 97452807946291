import { IndicatorVm, Serie } from "@/api/api";
import { Mutations } from "vuex-smart-module";
import PlotState, { PatientData } from "./state";

export default class PlotMutations extends Mutations<PlotState> {
  setIndicators(indicators: IndicatorVm[]) {
    this.state.indicators = indicators;
    this.state.selectedIndicators = [];
    this.state.loading = false;
  }

  setSelectedIndicators(indicators: IndicatorVm[]) {
    this.state.selectedIndicators = indicators;
  }

  addSelectedIndicator(indicator: IndicatorVm) {
    this.state.selectedIndicators = [
      ...this.state.selectedIndicators,
      indicator,
    ];
  }

  removeSelectedIndicator(indicator: IndicatorVm) {
    this.state.selectedIndicators = this.state.selectedIndicators!.filter(
      (x) => indicator != x
    );
  }

  setPatientData(patient: PatientData) {
    this.state.patient = patient;
  }

  setIndicatorSeries(payload: { indicator: IndicatorVm; series: Serie[] }) {
    if (
      this.state.indicatorsSeries.some((s) => s.indicator == payload.indicator)
    ) {
      const serie = this.state.indicatorsSeries.find(
        (x) => x.indicator == payload.indicator
      );
      serie!.series = payload.series;
    } else {
      this.state.indicatorsSeries?.push(payload);
    }
  }
}
