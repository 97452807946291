import { Mutations } from "vuex-smart-module";
import AuthState from "./state";
import JwtService from "@/services/jwt.service";

export default class ResultMutations extends Mutations<AuthState> {
  setLoading(loading = true) {
    this.state.loading = loading;
  }

  setError(payload: string[]) {
    this.state.errors = payload;
    this.state.loading = false;
  }

  setAuth(payload: { token: string; expiredAt: string }) {
    this.state.isAuthenticated = true;
    this.state.loading = false;
    this.state.errors = null;
    JwtService.saveToken(
      JSON.stringify({
        token: payload.token,
        expiredAt: payload.expiredAt,
      }),
      this.state.rememberMe
    );
  }

  setPhone(payload: {
    phone: string;
    birthDay: string;
    token?: string | null;
    rememberMe: boolean;
  }) {
    this.state.isAuthenticated = false;
    this.state.phone = payload.phone;
    this.state.linkToken = payload.token;
    this.state.rememberMe = payload.rememberMe;
    localStorage.setItem("rememberMe", JSON.stringify(payload.rememberMe));
    this.state.birthDay = payload.birthDay;
    this.state.errors = null;
    JwtService.destroyToken();
  }

  setLogin(payload: string) {
    this.state.phone = payload;
  }

  purgeAuth() {
    this.state.isAuthenticated = false;
    this.state.phone = null;
    this.state.rememberMe = false;
    this.state.errors = [];
    JwtService.destroyToken();
  }
}
