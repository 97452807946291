



































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SingleOtpInput extends Vue {
  @Prop() value!: string;
  @Prop({ default: "" }) separator!: string;
  @Prop({ default: true }) focus!: boolean;
  @Prop({ default: "" }) inputClasses!: string;
  @Prop({ default: false }) shouldAutoFocus!: boolean;
  @Prop({ default: true }) isInputNum!: boolean;
  @Prop({ default: true }) isLastChild!: boolean;

  model = "";

  mounted() {
    if (this.$refs.input && this.focus && this.shouldAutoFocus) {
      (this.$refs.input as HTMLInputElement).focus();
    }
  }

  select() {
    (this.$refs.input as HTMLInputElement).click();
    (this.$refs.input as HTMLInputElement).focus();
    (this.$refs.input as HTMLInputElement).select();
  }

  @Watch("value")
  valueChanged(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      this.model = newValue;
    }
  }
  // whenever question changes, this function will run
  @Watch("focus")
  focusChanged(newFocusValue: boolean, oldFocusValue: boolean) {
    // Check if focusedInput changed
    // Prevent calling function if input already in focus
    if (oldFocusValue !== newFocusValue && this.$refs.input && this.focus) {
      (this.$refs.input as HTMLInputElement).focus();
      (this.$refs.input as HTMLInputElement).select();
    }
  }

  handleOnChange() {
    if (this.model.length > 1) {
      this.model = this.model.slice(0, 1);
    }
    return this.$emit("on-change", this.model);
  }

  handleOnKeyDown(event: KeyboardEvent) {
    // Only allow characters 0-9, DEL, Backspace and Pasting
    const keyEvent = event || window.event;
    const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
    if (
      this.isCodeNumeric(charCode) ||
      charCode === 8 ||
      charCode === 86 ||
      charCode === 46
    ) {
      this.$emit("on-keydown", event);
    } else {
      keyEvent.preventDefault();
    }
  }

  isCodeNumeric(charCode: number) {
    // numeric keys and numpad keys
    return (
      (charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)
    );
  }

  handleOnPaste(event: Event) {
    return this.$emit("on-paste", event);
  }

  handleOnFocus() {
    (this.$refs.input as HTMLInputElement).select();
    return this.$emit("on-focus");
  }

  handleOnBlur() {
    return this.$emit("on-blur");
  }
}
