import { Store } from "vuex";
import { Actions, Context } from "vuex-smart-module";
import ResultState from "./state";
import ResultMutations from "./mutations";
import ResultGetters from "./getters";
import { IndicatorVm, PlotClient, Serie } from "@/api/api";
import AuthService from "@/services/auth.service";
import error from "../error";
import layout from "../layout";

export default class ResultActions extends Actions<
  ResultState,
  ResultGetters,
  ResultMutations,
  ResultActions
> {
  error!: Context<typeof error>;
  layout!: Context<typeof layout>;

  $init(store: Store<any>): void {
    this.error = error.context(store);
    this.layout = layout.context(store);
  }

  async switchIndicator(indicator: IndicatorVm) {
    if (this.state.selectedIndicators.includes(indicator)) {
      this.mutations.removeSelectedIndicator(indicator);
    } else {
      this.mutations.addSelectedIndicator(indicator);

      const client = new PlotClient();
      await client.setAuthToken(AuthService.refreshToken);
      return client
        .getindicatorseries(
          this.state.patient!.patientId,
          this.state.patient!.from,
          this.state.patient!.to,
          indicator.CommonIndicatorId,
          indicator.StudyTypeId,
          indicator.BiomaterialId
        )
        .then((result: Serie[]) => {
          this.mutations.setIndicatorSeries({ indicator, series: result });
        })
        .catch((exception) => {
          const errors = JSON.parse(exception.response);
          if (errors.error != undefined ) {
            const validationErrors = JSON.parse(errors.error);
            if(Array.isArray(validationErrors)){
              this.error.mutations.setError(Object.values(validationErrors));
            } else{
              this.error.mutations.setError([errors.error]);
            }
          } else {
            this.error.mutations.setError(exception.response);
          }
        });
    }
  }

  async selectIndicator(indicator: IndicatorVm | null) {
    if (indicator == null) {
      this.mutations.setSelectedIndicators([]);
      return;
    }

    this.mutations.setSelectedIndicators([indicator]);
    const client = new PlotClient();
    await client.setAuthToken(AuthService.refreshToken);
    return client
      .getindicatorseries(
        this.state.patient!.patientId,
        this.state.patient!.from,
        this.state.patient!.to,
        indicator.CommonIndicatorId,
        indicator.StudyTypeId,
        indicator.BiomaterialId
      )
      .then((result: Serie[]) => {
        this.mutations.setIndicatorSeries({ indicator, series: result });
      })
      .catch((exception) => {
        const errors = JSON.parse(exception.response);
        if (errors.error != undefined ) {
          const validationErrors = JSON.parse(errors.error);
          if(Array.isArray(validationErrors)){
            this.error.mutations.setError(Object.values(validationErrors));
          } else{
            this.error.mutations.setError([errors.error]);
          }
        } else {
          this.error.mutations.setError(exception.response);
        }
      });
  }

  async loadIndicators(payload: {
    patientId: string;
    from?: string;
    to?: string;
  }) {
    this.layout.mutations.setPageLoading(true);
    this.mutations.setPatientData(payload);
    const client = new PlotClient();
    await client.setAuthToken(AuthService.refreshToken);
    return client
      .getindicators(payload.patientId, payload.from, payload.to)
      .then((result: IndicatorVm[]) => {
        this.mutations.setIndicators(result);
        this.layout.mutations.setPageLoading(false);
      })
      .catch((exception) => {
        this.layout.mutations.setPageLoading(false);
        const errors = JSON.parse(exception.response);
        if (errors.error != undefined ) {
          const validationErrors = JSON.parse(errors.error);
          if(Array.isArray(validationErrors)){
            this.error.mutations.setError(Object.values(validationErrors));
          } else{
            this.error.mutations.setError([errors.error]);
          }
        } else {
          this.error.mutations.setError(exception.response);
        }
      });
  }

  async sendToEmail(payload: { file: Blob; patientId: string }) {
    const client = new PlotClient();
    await client.setAuthToken(AuthService.refreshToken);
    return client.sendtoemail(payload.patientId, {
      data: payload.file,
      fileName: "report.pdf",
    });
  }
}
