






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SingleOtpInput from "./SingleOtpInput.vue";

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

@Component({
  components: {
    SingleOtpInput,
  },
})
export default class OtpInput extends Vue {
  @Prop({ default: 4 }) numInputs!: number;
  @Prop({ default: "" }) separator!: string;
  @Prop({ default: "" }) inputClasses!: string;
  @Prop({ default: true }) isInputNum!: boolean;
  @Prop({ default: false }) shouldAutoFocus!: boolean;

  activeInput = 0;
  otp: string[] = [];
  oldOtp: string[] = [];

  handleOnFocus(index: number) {
    this.activeInput = index;
  }

  handleOnBlur() {
    this.activeInput = -1;
  }

  // Helper to return OTP from input
  checkFilledAllInputs() {
    if (this.otp.join("").length === this.numInputs) {
      return this.$emit("on-complete", this.otp.join(""));
    }
    return "Wait until the user enters the required number of characters";
  }

  focus() {
    this.activeInput = 0;
    (this.$refs.pwdinput as SingleOtpInput[])[0].select();
  }

  // Focus on input by index
  focusInput(input: number) {
    this.activeInput = Math.max(Math.min(this.numInputs - 1, input), 0);
  }

  // Focus on next input
  focusNextInput() {
    this.focusInput(this.activeInput + 1);
  }
  // Focus on previous input
  focusPrevInput() {
    this.focusInput(this.activeInput - 1);
  }

  // Change OTP value at focused input
  changeCodeAtFocus(value: string) {
    this.oldOtp = Object.assign([], this.otp);
    this.$set(this.otp, this.activeInput, value);
    if (this.oldOtp.join("") !== this.otp.join("")) {
      this.$emit("on-change", this.otp.join(""));
      this.checkFilledAllInputs();
    }
  }
  // Handle pasted OTP
  handleOnPaste(event: ClipboardEvent) {
    event.preventDefault();
    if (event.clipboardData == null) return;
    const pastedData = event.clipboardData
      .getData("text/plain")
      .slice(0, this.numInputs - this.activeInput)
      .split("");
    if (this.isInputNum && !pastedData.join("").match(/^\d+$/)) {
      return "Invalid pasted data";
    }
    // Paste data from focused input onwards
    const currentCharsInOtp = this.otp.slice(0, this.activeInput);
    const combinedWithPastedData = currentCharsInOtp.concat(pastedData);
    this.$set(this, "otp", combinedWithPastedData.slice(0, this.numInputs));
    this.focusInput(combinedWithPastedData.slice(0, this.numInputs).length);
    return this.checkFilledAllInputs();
  }

  handleOnChange(value: string) {
    this.changeCodeAtFocus(value);
    this.focusNextInput();
  }

  clearInput() {
    if (this.otp.length > 0) {
      this.$emit("on-change", "");
    }
    this.otp = [];
    this.activeInput = 0;
  }

  // Handle cases of backspace, delete, left arrow, right arrow
  handleOnKeyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case BACKSPACE:
        event.preventDefault();
        this.changeCodeAtFocus("");
        this.focusPrevInput();
        break;
      case DELETE:
        event.preventDefault();
        this.changeCodeAtFocus("");
        break;
      case LEFT_ARROW:
        event.preventDefault();
        this.focusPrevInput();
        break;
      case RIGHT_ARROW:
        event.preventDefault();
        this.focusNextInput();
        break;
      default:
        break;
    }
  }
}
