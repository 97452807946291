import { BidVm, PatientServiceRequestVm } from "@/api/api";
import { Mutations } from "vuex-smart-module";
import ResultState from "./state";

export default class ResultMutations extends Mutations<ResultState> {
  setFilterDateRange(payload: string[]) {
    if (this.state.filter == null) {
      this.state.filter = { dateRange: payload };
      return;
    }
    this.state.filter = { ...this.state.filter, dateRange: payload };
  }

  setListLoading() {
    this.state.listLoading = true;
  }

  setResultLoading(payload: boolean) {
    this.state.resultLoading = payload;
  }
  setNewResultsCount(payload: number) {
    this.state.newResultsCount = payload;
  }

  setResultsAsViewed(payload: string) {
    const order = this.state.resultList.find(
      (x) => x.serviceRequestId === payload
    );
    if (!order) return;
    order.isNew = false;
  }

  setPatientResultList(payload: PatientServiceRequestVm[]) {
    this.state.resultList = payload;
    this.state.results = [];
    this.state.listLoading = false;
  }

  setPatientResult(payload: { serviceRequestId: string; results: BidVm[] }) {
    if (this.state.results.some((x) => x.key === payload.serviceRequestId)) {
      const item = this.state.results.find(
        (x) => x.key === payload.serviceRequestId
      );
      Object.assign(item, {
        key: payload.serviceRequestId,
        values: payload.results,
      });
    } else {
      this.state.results.push({
        key: payload.serviceRequestId,
        values: payload.results,
      });
    }
  }
}
