import Vue from "vue";
import Vuex from "vuex";

import { Module, createStore } from "vuex-smart-module";
import auth from "./auth";
import results from "./home";
import layout from "./layout";
import error from "./error";
import profile from "./profile";
import plot from "./plot";

Vue.use(Vuex);

const root = new Module({
  modules: {
    auth,
    results,
    layout,
    error,
    profile,
    plot,
  },
});

const store = createStore(root);

export default store;

export const authModule = auth.context(store);
export const layoutModule = layout.context(store);
export const resultsModule = results.context(store);
export const errorModule = error.context(store);
export const profileModule = profile.context(store);
export const plotModule = plot.context(store);
