import { IndicatorVm, Serie } from "@/api/api";

export interface PatientData {
  patientId: string;
  from?: string;
  to?: string;
}

export default class State {
  loading = false;
  patient: PatientData | null = null;
  indicators: IndicatorVm[] | null = null;
  indicatorsSeries: { indicator: IndicatorVm; series: Serie[] }[] = [];
  selectedIndicators: IndicatorVm[] = [];
}
