import { BidVm, PatientServiceRequestVm } from "@/api/api";

export default class ResultState {
  newResultsCount = 0;
  listLoading = false;
  resultLoading = false;
  resultList: PatientServiceRequestVm[] = [];
  filter: { dateRange: string[] | null } = { dateRange: null };
  results: { key: string; values: BidVm[] }[] = [];
}
