import { Mutations } from "vuex-smart-module";
import LayoutState from "./state";

export default class LayoutMutations extends Mutations<LayoutState> {
  setPageLoading(payload: boolean) {
    this.state.pageLoading = payload;
  }

  toggleAsideMenu() {
    if (this.state.asideMenuClasses.some((x) => x === "aside-on")) {
      this.state.asideMenuClasses = this.state.asideMenuClasses.filter(
        (x) => x !== "aside-on"
      );
    } else {
      this.state.asideMenuClasses.push("aside-on");
    }
  }

  toggleMobileMenu() {
    if (
      this.state.mobileMenuClasses.some((x) => x === "header-menu-wrapper-on")
    ) {
      this.state.mobileMenuClasses = this.state.mobileMenuClasses.filter(
        (x) => x !== "header-menu-wrapper-on"
      );
    } else {
      this.state.mobileMenuClasses.push("header-menu-wrapper-on");
    }
  }

  closeMobileMenu() {
    this.state.mobileMenuClasses = this.state.mobileMenuClasses.filter(
      (x) => x !== "header-menu-wrapper-on"
    );
  }
}
