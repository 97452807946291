const ID_TOKEN_KEY = "id_token";

export const getToken = (): string | null => {
  return (
    window.sessionStorage.getItem(ID_TOKEN_KEY) ||
    window.localStorage.getItem(ID_TOKEN_KEY)
  );
};

export const saveToken = (token: string, remeber = true) => {
  window.sessionStorage.setItem(ID_TOKEN_KEY, token);
  if (remeber) {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  }
};

export const destroyToken = () => {
  window.sessionStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
