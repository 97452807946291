import JwtService from "@/services/jwt.service";
import dayjs from "dayjs";

export default class ApiBase {
  authToken: string | null;

  protected constructor() {
    this.authToken = null;
  }

  async setAuthToken(refreshtokenfunc: () => Promise<any>) {
    let token = JwtService.getToken();
    if (
      token == undefined ||
      token == "" ||
      dayjs(JSON.parse(token).expiredAt).isBefore(dayjs())
    ) {
      try {
        await refreshtokenfunc();
        token = JwtService.getToken();
        if (token == undefined || token == "") return;
        this.authToken = JSON.parse(token).token;
      } catch (exception) {
        console.debug(exception);
      }
    } else {
      this.authToken = JSON.parse(token).token;
    }
  }

  protected transformOptions(options: any): Promise<any> {
    if (this.authToken == null) return Promise.resolve(options);
    options.headers = Object.assign(options.headers, {
      Authorization: `Bearer ${this.authToken}`,
    });
    return Promise.resolve(options);
  }
}
