import { Actions, Context } from "vuex-smart-module";
import ResultState from "./state";
import ResultMutations from "./mutations";
import ResultGetters from "./getters";
import { BidClient, BidVm, PatientServiceRequestVm } from "@/api/api";
import AuthService from "@/services/auth.service";
import error from "../error";
import { Store } from "vuex";

export default class ResultActions extends Actions<
  ResultState,
  ResultGetters,
  ResultMutations,
  ResultActions
> {
  error!: Context<typeof error>;

  $init(store: Store<any>): void {
    this.error = error.context(store);
  }
  markResultAsViewed(payload: string) {
    const client = new BidClient();
    client.setAuthToken(AuthService.refreshToken);
    client
      .markasviewed(payload)
      .then(() => {
        this.mutations.setResultsAsViewed(payload);
        this.actions.getNewResultsCount();
      })
      .catch((exception) => {
        const errors = JSON.parse(exception.response);
        if (errors.error != undefined ) {
          const validationErrors = JSON.parse(errors.error);
          if(Array.isArray(validationErrors)){
            this.error.mutations.setError(Object.values(validationErrors));
          } else{
            this.error.mutations.setError([errors.error]);
          }
        } else {
          this.error.mutations.setError(exception.response);
        }
      });
    //throw "not implemented";
  }

  loadPatientResultList(payload: string) {
    this.mutations.setListLoading();
    const client = new BidClient();
    client.setAuthToken(AuthService.refreshToken);
    client
      .getresultlist(payload)
      .then((result: PatientServiceRequestVm[]) => {
        this.mutations.setPatientResultList(result);
      })
      .catch((exception) => {
        const errors = JSON.parse(exception.response);
        if (errors.error != undefined ) {
          const validationErrors = JSON.parse(errors.error);
          if(Array.isArray(validationErrors)){
            this.error.mutations.setError(Object.values(validationErrors));
          } else{
            this.error.mutations.setError([errors.error]);
          }
        } else {
          this.error.mutations.setError(exception.response);
        }
      });
  }

  getNewResultsCount() {
    const client = new BidClient();
    client.setAuthToken(AuthService.refreshToken);
    client
      .getnewbidscount()
      .then((result: number) => {
        this.mutations.setNewResultsCount(result);
      })
      .catch((exception) => {
        const errors = JSON.parse(exception.response);
        if (errors.error != undefined ) {
          const validationErrors = JSON.parse(errors.error);
          if(Array.isArray(validationErrors)){
            this.error.mutations.setError(Object.values(validationErrors));
          } else{
            this.error.mutations.setError([errors.error]);
          }
        } else {
          this.error.mutations.setError(exception.response);
        }
      });
  }

  loadPatientResult(payload: string) {
    this.mutations.setResultLoading(true);
    return new Promise((resolve, reject) => {
      const client = new BidClient();
      client.setAuthToken(AuthService.refreshToken).then(() => {
        client
          .getresult(payload)
          .then((result: BidVm[]) => {
            this.mutations.setPatientResult({
              serviceRequestId: payload,
              results: result,
            });
            this.actions.markResultAsViewed(payload);
            resolve({ serviceRequestId: payload, results: result });
          })
          .catch((exception) => {
            const errors = JSON.parse(exception.response);
            if (errors.error != undefined ) {
              const validationErrors = JSON.parse(errors.error);
              if(Array.isArray(validationErrors)){
                this.error.mutations.setError(Object.values(validationErrors));
              } else{
                this.error.mutations.setError([errors.error]);
              }
            } else {
              this.error.mutations.setError(exception.response);
            }
            reject();
          });
      });
    });
  }
}
