import { PatientVm } from "@/api/api";
import { Mutations } from "vuex-smart-module";
import ProfileState from "./state";

export default class ProfileMutations extends Mutations<ProfileState> {
  setLoading() {
    this.state.loading = true;
  }

  setPatients(patinets: PatientVm[]) {
    this.state.patients = patinets;
    this.state.patient = null;
    this.state.loading = false;
  }

  setPatient(patinet: PatientVm) {
    this.state.patient = patinet;
  }

  updatePatient(patinet: PatientVm) {
    const index = this.state.patients.findIndex(
      (p) => p.patientId !== patinet.patientId
    );
    this.state.patients[index] = patinet;
    this.state.patient = patinet;
  }
}
