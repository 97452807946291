import { Module } from "vuex-smart-module";
import mutations from "./mutations";
import state from "./state";

const error = new Module({
  mutations: mutations,
  state: state,
});

export default error;
