import dayjs from "dayjs";
import { Getters } from "vuex-smart-module";
import ResultState from "./state";

export default class ResultGetters extends Getters<ResultState> {
  get resultList() {
    if (this.state.filter.dateRange == null) return this.state.resultList;
    const minDate = dayjs(this.state.filter.dateRange[0]);
    const maxDate = dayjs(this.state.filter.dateRange[1]).add(1, "day");
    return this.state.resultList.filter((x) => {
      const created = dayjs(x.created);
      return created.isAfter(minDate) && created.isBefore(maxDate);
    });
  }
}
