import { Actions, Context } from "vuex-smart-module";
import ProfileState from "./state";
import ProfileMutations from "./mutations";
import ProfileGetters from "./getters";
import { Store } from "vuex";
import layout from "../layout";
import auth from "../auth";
import error from "../error";

import { PatientClient, PatientVm } from "@/api/api";
import AuthService from "@/services/auth.service";

export interface UpdatePatientVm extends PatientVm {
  phoneConfirmationToken: string | undefined;
}

export default class ProfileActions extends Actions<
  ProfileState,
  ProfileGetters,
  ProfileMutations,
  ProfileActions
> {
  layout!: Context<typeof layout>;
  auth!: Context<typeof auth>;
  error!: Context<typeof error>;

  $init(store: Store<any>): void {
    this.layout = layout.context(store);
    this.auth = auth.context(store);
    this.error = error.context(store);
  }

  async loadPatients() {
    this.layout.mutations.setPageLoading(true);
    this.mutations.setLoading();
    const client = new PatientClient();
    await client.setAuthToken(AuthService.refreshToken);
    client
      .list()
      .then((result: PatientVm[]) => {
        this.mutations.setPatients(result);
        if (result.length > 0) {
          this.mutations.setPatient(result[0]);
        }
        this.layout.mutations.setPageLoading(false);
      })
      .catch((exception) => {
        this.layout.mutations.setPageLoading(false);
        const errors = JSON.parse(exception.response);
        if (errors.error != undefined ) {
          const validationErrors = JSON.parse(errors.error);
          if(Array.isArray(validationErrors)){
            this.error.mutations.setError(Object.values(validationErrors));
          } else{
            this.error.mutations.setError([errors.error]);
          }
        } else {
          this.error.mutations.setError(exception.response);
        }
      });
  }

  updatePatientProfile(patient: UpdatePatientVm) {
    return new Promise<void>((resolve, reject) => {
      const client = new PatientClient();
      client.setAuthToken(AuthService.refreshToken).then(() => {
        client
          .updateprofile(patient)
          .then(() => {
            this.mutations.updatePatient(patient);
            if (patient.phoneConfirmationToken != null) {
              this.auth.actions.relogin(patient.phoneNumber!).then(() => {
                this.actions.loadPatients();
              });
            }
            resolve();
          })
          .catch((exception) => {
            const errors = JSON.parse(exception.response);
            if (errors.error != undefined ) {
              const validationErrors = JSON.parse(errors.error);
              if(Array.isArray(validationErrors)){
                this.error.mutations.setError(Object.values(validationErrors));
              } else{
                this.error.mutations.setError([errors.error]);
              }
            } else {
              this.error.mutations.setError(exception.response);
            }
            reject();
          });
      });
    });
  }
}
